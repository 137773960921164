<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Login v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <b-img :src="require('@/assets/images/logo/512.svg')" width="240" height="240" />
        </b-link>

        <b-card-title class="mb-1">
          Welcome to  ShadowTech.vip
        </b-card-title>
        <b-card-text class="mb-2">
          ShadowTech Auto iOS
        </b-card-text>

        <b-tabs>
          <b-tab title="Login as Admin">
            <!-- form -->
            <validation-observer
              ref="loginForm"
              #default="{invalid}"
            >
              <b-form
                class="auth-login-form mt-2"
                @submit="onSubmit"
                method='post'
                ref="vue_1"
              >

                <!-- email -->
                <b-form-group
                  label-for="Email or Username"
                  label="Email or Username"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Email"
                    rules="required"
                  >
                    <b-form-input
                      id="username"
                      v-model="email"
                      name="login-user"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Email"
                      autofocus
                      @change="show_otp = false"

                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- password -->
                <b-form-group>
                  <div class="d-flex justify-content-between">
                    <label for="password">Password</label>
                    <b-link :to="{name:'forgot-password'}">
                      <small>Forgot Password?</small>
                    </b-link>
                  </div>
                  <validation-provider
                    #default="{ errors }"
                    name="Password"
                    rules="required"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid':null"
                    >
                      <b-form-input
                        id="password"
                        v-model="password"
                        :type="passwordFieldType"
                        class="form-control-merge"
                        :state="errors.length > 0 ? false:null"
                        name="login-password"
                        placeholder="Password"
                      />

                      <b-input-group-append is-text>
                        <feather-icon
                          class="cursor-pointer"
                          :icon="passwordToggleIcon"
                          @click="togglePasswordVisibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- OTP -->
                <b-form-group
                  label-for="OTP"
                  label="OTP"
                  v-if="show_otp"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="OTP"
                    rules="required"
                  >
                    <b-form-input
                      id="otp_"
                      v-model="otp"
                      name="otp-user"
                      :state="errors.length > 0 ? false:null"
                      placeholder="OTP"
                      autofocus
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- checkbox -->
                <b-form-group>
                  <b-form-checkbox
                    v-model="remember"
                    name="checkbox-1"
                  >
                    Remember Me
                  </b-form-checkbox>
                </b-form-group>

                <!-- submit button -->
                <b-button
                  variant="primary"
                  type="submit"
                  block
                  :disabled="invalid"
                >
                <b-spinner v-if="processing"
                  small
                />
                  Sign in
                </b-button>
              </b-form>
            </validation-observer>
          </b-tab>
        </b-tabs>
        <b-card-text class="text-center mt-2">
          <div>
            <b-link :to="{name:'register'}">
              <span>Create an account?</span>
            </b-link>
            <br>
            <!-- <b-link :to="{name:'register'}">
              <span>Contact Supprot</span>
            </b-link> -->
            <b-link href="https://www.facebook.com/messages/t/shadowtech.vip" target="_blank">
              <span>Chat Support</span>
            </b-link>
          </div>
          
        </b-card-text>
        
        <template>
          <div>
            <b-toast id="alert_toast" :variant="toastConfig.variant" solid>
              <template #toast-title>
                <feather-icon :icon="toastConfig.icon" />
                <div class="d-flex flex-grow-1 align-items-baseline">
                  <strong class="mr-auto"> {{toastConfig.title}}!</strong>
                </div>
              </template>
              {{toastConfig.message}}
            </b-toast>
          </div>
        </template>
      </b-card>
      <!-- /Login v1 -->
     
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton, BForm, BFormInput, BFormGroup, BCard, BLink, BCardTitle, BCardText, BInputGroup, BInputGroupAppend, BFormCheckbox, BSpinner, BImg,
  BTab,BTabs
} from 'bootstrap-vue'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import router from '@/router'
import CryptoJS from 'crypto-js'


const key = '82f2ceed4c503896c8a291e560bd4325' // change to your key
const iv = 'sinasinasisinaaa' // change to your iv
const apiKey = '123xxxyyyzzz' // change to your api key

export default {
  components: {
    // BSV
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    BLink,
    VuexyLogo,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    BSpinner,
    BImg,
    BTab,
    BTabs
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      processing: false,
      email: '',
      password: '',
      kuser: '',
      kpassword: '',
      status: '',
      remember: false,
      // validation rules
      required,
      email,
      otp: "",
      show_otp: false,
      toastConfig:{
        variant: 'danger',
        title: 'Test',
        icon: 'AlertOctagonIcon',
        message: 'Test message'
      }
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  created(){
    this.remember = localStorage.getItem('remember')
    if(this.remember){
      this.email = this.aesDencrypt(localStorage.getItem('email') || "")
      this.password = this.aesDencrypt(localStorage.getItem('password') || "")
      this.kuser = this.aesDencrypt(localStorage.getItem('keyport') || "")
      this.kpassword = this.aesDencrypt(localStorage.getItem('kpassword') || "")
    }
  },
  methods: {
    aesEncrypt(txt) {
      const cipher = CryptoJS.AES.encrypt(txt, CryptoJS.enc.Utf8.parse(key), {
        iv: CryptoJS.enc.Utf8.parse(iv),
        mode: CryptoJS.mode.CBC
      })

      return cipher.toString()
    },
    aesDencrypt(txt) {
      if(!txt)
        return null
      const cipher = CryptoJS.AES.decrypt(txt, CryptoJS.enc.Utf8.parse(key), {
        iv: CryptoJS.enc.Utf8.parse(iv),
        mode: CryptoJS.mode.CBC
      })

      return CryptoJS.enc.Utf8.stringify(cipher).toString()
    },
    async onSubmit(event){
      event.preventDefault()
      this.$gtag.event('login', { method: 'email' })
      localStorage.setItem('remember',this.remember)
      
       this.processing = true
        this.$store.dispatch('auth/login',{
          email: this.email,
          password: this.password,
          otp: this.otp,
          token: "token"
        }).then(result => {
          if(result.otp_require){
            this.otp = ""
            this.show_otp = true
            this.processing = false
            return
          }
          if(this.remember){
            localStorage.setItem('email',this.aesEncrypt(this.email))
            localStorage.setItem('password',this.aesEncrypt(this.password))
          }
          this.toastConfig = {
            variant: 'success',
            title: 'Login Alert',
            img: 'AlertOctagonIcon',
            message: 'Login Success',
          }
            this.$bvToast.show('alert_toast')
            setTimeout(()=>{
              console.log(result)
              if(result.userInfo.role == 0){
                location.href = '/a/panel'  
              }else{
                location.href = '/panel'  
              }
          },1000)
          }).catch(e => {
            console.log(e)
            this.processing = false
            this.toastConfig = {
                variant: 'danger',
                title: 'Login Alert',
                icon: 'AlertOctagonIcon',
                message: "Login failed"
              }
              this.$bvToast.show('alert_toast')

            // if(e.response.data.msg.includes('Pending Account')){
            //   this.$swal({
            //     title: 'Pending',
            //     html: `Your account is not active</br>Please check your email (Maybe check in spam box) or contact us:</br>
            //     <a href="https://www.facebook.com/messages/t/shadowtech.vip" target="_blank">Chat Facebook</a> or <a href="skype:support@shadowtech.vip?chat" class="fa fa-facebook">Chat Skype</a></br>
            //       We will quickly verify and activate your account.</br>
            //       Tài khoản của bạn chưa được kích hoạt</br>Vui lòng kiểm tra email (Có thể nằm ở mục spam) hoặc liên hệ với chúng tôi</br>
            //       <a href="https://www.facebook.com/messages/t/shadowtech.vip" target="_blank">Chat Facebook</a> hoặc <a href="skype:support@shadowtech.vip?chat" class="fa fa-facebook">Chat Skype</a></br>
            //       Chúng tôi sẽ xác thực và kích hoạt tài khoản cho bạn một cách nhanh chóng</br>
            //       <a href="https://www.facebook.com/messages/t/shadowtech.vip" target="_blank"><button type="button" class="swal2-confirm btn btn-primary" aria-label="" style="display: inline-block;">Chat Facebook</button></a>
            //       <a href="skype:support@shadowtech.vip?chat" target="_blank"><button type="button" class="swal2-confirm btn btn-primary" aria-label="" style="display: inline-block;">Chat Skype</button></a>

            //     `,
            //     icon: 'error',
            //     confirmButtonText: 'Check your email',
            //     customClass: {
            //       confirmButton: 'btn btn-primary',
            //     },
            //     buttonsStyling: false,
            //   }).then(rs=>{
            //     this.$router.push('/login')
            //   })
            // }else{
            //   this.toastConfig = {
            //     variant: 'danger',
            //     title: 'Login Alert',
            //     icon: 'AlertOctagonIcon',
            //     message: e.response.data.msg
            //   }
            //   this.$bvToast.show('alert_toast')
            // }
          })
    },

    async onMemberSubmit(event){
      event.preventDefault()
      localStorage.setItem('remember',this.remember)
      
      this.processing = true
        
        this.$store.dispatch('auth/memberlogin',{
          keyport: this.kuser,
          kpassword: this.kpassword,
          token: ""
        }).then(result => {
          if(this.remember){
            localStorage.setItem('keyport',this.aesEncrypt(this.kuser))
            localStorage.setItem('kpassword',this.aesEncrypt(this.kpassword))
          }
          this.toastConfig = {
            variant: 'success',
            title: 'Login Alert',
            img: 'AlertOctagonIcon',
            message: 'Login Success',
          }
            this.$bvToast.show('alert_toast')
            setTimeout(()=>{
              location.href = '/m/manager'  
          },1000)
          }).catch(e => {
            this.processing = false
            this.toastConfig = {
              variant: 'danger',
              title: 'Login Alert',
              icon: 'AlertOctagonIcon',
              message: e.response.data.msg
            }
            this.$bvToast.show('alert_toast')

          })

      
      // await this.$recaptchaLoaded()
      
      // this.$recaptcha("login").then(token => {
      //   this.processing = true
        
      //   this.$store.dispatch('auth/memberlogin',{
      //     keyport: this.kuser,
      //     kpassword: this.kpassword,
      //     token: token
      //   }).then(result => {
      //     if(this.remember){
      //       localStorage.setItem('keyport',this.aesEncrypt(this.kuser))
      //       localStorage.setItem('kpassword',this.aesEncrypt(this.kpassword))
      //     }
      //     this.toastConfig = {
      //       variant: 'success',
      //       title: 'Login Alert',
      //       img: 'AlertOctagonIcon',
      //       message: 'Login Success',
      //     }
      //       this.$bvToast.show('alert_toast')
      //       setTimeout(()=>{
      //         location.href = '/m/manager'  
      //     },1000)
      //     }).catch(e => {
      //       this.processing = false
      //       this.toastConfig = {
      //         variant: 'danger',
      //         title: 'Login Alert',
      //         icon: 'AlertOctagonIcon',
      //         message: e.response.data.msg
      //       }
      //       this.$bvToast.show('alert_toast')

      //     })
      //   })
      }
    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>

//l8fk3ad2sWv9JQWV
//Rh8X&lZpnj#%KsBC